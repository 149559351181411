import { useUsers } from '@/hooks/useUsers'
import { Layout } from './Layout'
import { useHistory } from 'react-router-dom'
import download from '@/lib/download'

export const useProps = (): FCProps<typeof Layout> => {
  const { users } = useUsers()
  const history = useHistory()

  const getContractLabel = (contractStatus: App.User['contractStatus']): string => {
    switch (contractStatus) {
      case 'underContract':
        return '契約中'
      case 'canceled':
        return '解約済'
      case 'suspended':
        return '休止中'
      case 'cancelScheduled':
        return '解約予定'
      case 'suspendScheduled':
        return '休止予定'
      default:
        return ''
    }
  }

  const getGenderLabel = (gender: App.User['gender']) => {
    switch (gender) {
      case 'male':
        return '男性'
      case 'female':
        return '女性'
      case 'other':
        return 'その他/無回答'
      default:
        return '未回答'
    }
  }

  const downloadCsv = (selectedRowKeys: (string | number)[]) => {
    if (!selectedRowKeys) return

    const title = [
      'ユーザーID',
      '顧客番号',
      'メールアドレス',
      'ニックネーム',
      '生年月日',
      '性別',
      '郵便番号',
      '契約状況',
      '契約更新日時',
      '登録日時',
    ].join(',')
    const contents = users
      .filter(p => p.id && selectedRowKeys.includes(p.id))
      .map(user => {
        return [
          user.id,
          user.customerNumber,
          user.emailAddress,
          user.nickName,
          user.birthday ? user.birthday.toDate().toLocaleDateString('ja-JP') : '未回答',
          getGenderLabel(user.gender),
          user.postalCode,
          getContractLabel(user.contractStatus),
          user.contractStatusUpdatedAt ? user.contractStatusUpdatedAt.toDate().toLocaleDateString('ja-JP') : '',
          user.createdAt.toDate().toLocaleString('ja-JP'),
        ].join(',')
      })
      .join('\n')
    download('uers.csv', title + '\n' + contents)
  }

  return {
    users,
    getContractLabel,
    onPressShow: async id => {
      history.push(`${history.location.pathname}/${id}`)
    },
    onPressDownload: downloadCsv,
  }
}
